import "../../css/footer.css";

export default function Footer() {
  return (
    <div className="footer-container">
      <div>
        <button
          className="btn btn-lg"
          style={{ fontVariant: "all-small-caps", color: "white" }}
        >
          Showboat
        </button>
      </div>

      <div>
        <button className="btn btn-lg" style={{ color: "white" }}>
          <i className="bi bi-instagram" />
        </button>
        <button className="btn btn-lg" style={{ color: "white" }}>
          <i className="bi bi-twitter-x" />
        </button>
      </div>
    </div>
  );
}
