import Header from '../components/navigation/Header';

export default function Profile() {
  return (
    <>
      <Header />
      <h1>Profile</h1>
    </>
  );
}
