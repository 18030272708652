import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigator = useNavigate();

  const [firstName, setFirstName] = useState("");
  const handleFirstNameInputChange = (evt: any) => {
    setFirstName(evt.target.value);
  };

  const [lastName, setLastName] = useState("");
  const handleLastNameInputChange = (evt: any) => {
    setLastName(evt.target.value);
  };

  const [email, setEmail] = useState("");
  const handleEmailInputChange = (evt: any) => {
    setEmail(evt.target.value);
  };

  const handleOnSubmit = () => {
    navigator("home");
  };
  return (
    <>
      <h1>Login</h1>
      <button
        onClick={() => {
          navigator("home");
        }}
      >
        Login
      </button>

      <h1>Create an account</h1>
      <input
        type="text"
        placeholder="First name"
        aria-label="First name"
        value={firstName}
        onChange={handleFirstNameInputChange}
      ></input>

      <input
        type="text"
        placeholder="Last name"
        aria-label="Last name"
        value={lastName}
        onChange={handleLastNameInputChange}
      ></input>

      <input
        type="text"
        placeholder="Email"
        aria-label="Email"
        value={email}
        onChange={handleEmailInputChange}
      ></input>

      <button onClick={handleOnSubmit}>Submit</button>
    </>
  );
}
