import { useId, useState } from 'react';
import '../css/activity-form.css';

export default function ActivityForm() {
  const [title, setTitle] = useState('');
  const titleId = useId();
  const handleTitleInputChange = (evt: any) => {
    setTitle(evt.target.value);
  };

  const [artist, setArtist] = useState('');
  const artistId = useId();
  const handleArtistInputChange = (evt: any) => {
    setArtist(evt.target.value);
  };

  const [date, setDate] = useState('');
  const dateId = useId();
  const handleDateInputChange = (evt: any) => {
    setDate(evt.target.value);
  };

  const [location, setLocation] = useState('');
  const locationId = useId();
  const handleLocationInputChange = (evt: any) => {
    setLocation(evt.target.value);
  };

  const handleSubmitClick = () => {
    const activityToSubmit = { activity: { title: title, artist: artist, date: date, location: location } };
    console.log(activityToSubmit);
  };

  return (
    <>
      <div className="activity-form-container">
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Title"
            aria-label="Title"
            value={title}
            onChange={handleTitleInputChange}
          ></input>
          <label htmlFor={titleId} className="form-label">
            Title
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Artist"
            aria-label="Artist"
            value={artist}
            onChange={handleArtistInputChange}
          ></input>
          <label htmlFor={artistId} className="form-label">
            Artist
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Location"
            id={locationId}
            aria-label="Location"
            value={location}
            onChange={handleLocationInputChange}
          ></input>
          <label htmlFor={locationId} className="form-label">
            Location
          </label>
        </div>

        <div className="form-floating mb-3">
          <input
            type="date"
            className="form-control"
            placeholder="Date"
            aria-label="Date"
            value={date}
            onChange={handleDateInputChange}
          ></input>
          <label htmlFor={dateId} className="form-label">
            Date
          </label>
        </div>

        <button className="btn btn-primary" onClick={handleSubmitClick} style={{ margin: '1em' }}>
          Create
        </button>
      </div>
    </>
  );
}
