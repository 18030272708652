import Avatar from "./Avatar";

import "../css/profile.css";

export default function Card() {
  /* User info to be fetched from DB */
  /* Passed in as Props */

  const name = "John Doe";
  const username = "@johndoe";
  const upcomingShows = 3;
  const showsAttended = 25;
  const joinedDate = "August, 2024";
  /* End User info block */

  return (
    <div className="card card-container">
      <div className="card-body">
        <Avatar size="150px" />
        <h5 className="card-title">{name}</h5>
        <h6>{username}</h6>
        <h6 className="profile-joined">Member since {joinedDate}</h6>

        <div className="profile-shows-container">
          <div className="profile-show-count-container">
            <h6>Upcoming Shows</h6>
            <p className="profile-show-count">{upcomingShows}</p>
          </div>
          <div
            className="profile-show-count-container"
            style={{ border: "none" }}
          >
            <h6>Shows Attended</h6>
            <p className="profile-show-count">{showsAttended}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
