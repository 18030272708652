import profile from "../images/profile.jpg";
import "../css/avatar.css";

interface Props {
  size: string;
}

export default function Avatar({ size }: Props) {
  return (
    <div
      className="image-container"
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={profile}
        className="rounded"
        style={{ width: size, height: size }}
        alt="Profile"
      />
    </div>
  );
}
