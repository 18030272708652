import ActivityForm from '../components/ActivityForm';
import Header from '../components/navigation/Header';

export default function NewActivity() {
  return (
    <>
      <Header />
      <h1>New Activity!</h1>
      <ActivityForm />
    </>
  );
}
