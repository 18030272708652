import Card from '../components/Card';
import Header from '../components/navigation/Header';
import Footer from '../components/navigation/Footer';
import Entry from '../components/Entry';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/home.css';
import Feed from '../components/Feed';

export default function Home() {
  const fetchUser = () => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users?userId=4`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => console.error('Error:', error));
  };
  fetchUser();
  return (
    <div>
      <Header />
      <div className="home-container">
        <Card />
        <Feed>
          {/* Feed objects to be fetched from DB */}
          <Entry artistName="Artist1" description="Sample description" />
          <Entry artistName="Artist2" description="Sample description" />
          <Entry artistName="Artist1" description="Sample description" />
          <Entry artistName="Artist2" description="Sample description" />
          <Entry artistName="Artist1" description="Sample description" />
          <Entry artistName="Artist2" description="Sample description" />
          <Entry artistName="Artist1" description="Sample description" />
          <Entry artistName="Artist2" description="Sample description" />
        </Feed>
      </div>
      <Footer />
    </div>
  );
}
