import "../css/entry.css";

interface Props {
  artistName: string;
  description: string;
}

export default function Entry({ artistName, description }: Props) {
  return (
    <div className="entry-container">
      <div className="entry-container-content">
        <h1>{artistName}</h1>
        <p>{description}</p>
        <button className="btn">
          <i className="bi bi-chat-left-text" />
        </button>
        <button className="btn">
          <i className="bi bi-hand-thumbs-up" />
        </button>
      </div>
    </div>
  );
}
