import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { Route, Routes } from 'react-router-dom';

import Home from './routes/Home';
import NewActivity from './routes/NewActivity';
import Login from './routes/Login';
import Profile from './routes/Profile';

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="home" element={<Home />} />
        <Route path="profile" element={<Profile />} />
        <Route path="new-activity" element={<NewActivity />} />
      </Routes>
    </>
  );
}
