import '../../css/header.css';
import Avatar from '../Avatar';

import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="header-container">
      <button
        className="btn btn-lg"
        style={{ margin: '1em', fontVariant: 'all-small-caps' }}
        onClick={() => navigate('/home')}
      >
        Showboat
      </button>

      <div className="header-navigation-buttons">
        <button aria-label="Notifications" className="btn">
          <i className="bi bi-bell"></i>
        </button>

        <button className="btn" onClick={() => navigate('/profile')}>
          <Avatar size="2em" />
        </button>

        <button aria-label="Add show" className="btn" onClick={() => navigate('/new-activity')}>
          <i className="bi bi-plus-circle"></i>
        </button>
      </div>
    </div>
  );
}
